const InfluKeys = {
  no_influencer: 'InfluKeys.no_influencer',

  title: 'InfluKeys.title',

  search: {
    option1: 'InfluKeys.search.option1',
    option2: 'InfluKeys.search.option2',
    option3: 'InfluKeys.search.option3',
    option4: 'InfluKeys.search.option4',
  },

  side_popup: {
    C: {
      join_creator: 'InfluKeys.side_popup.C.join_creator',
    },
  },

  M: {
    title: 'InfluKeys.M.title',

    search: 'InfluKeys.M.search',
  },

  Card: {
    my_card: 'InfluKeys.Card.my_card',
    creator_profile: 'InfluKeys.Card.creator_profile',
    view_package: 'InfluKeys.Card.view_package',
    view_packages: 'InfluKeys.Card.view_packages',
    view_analytics: 'InfluKeys.Card.view_analytics',
    send_a_message: 'InfluKeys.Card.send_a_message',
    view_profile: 'InfluKeys.Card.view_profile',
  },
};

export default InfluKeys;
