import styled from 'styled-components';
import Logo from '../Logo';
import IconButton from 'components/Basics/Buttons/IconButton';
import { HamburgerIcon } from 'assets/icons';
import { useToggle } from 'react-use';
import Sidebar from './Sidebar';
import Menu from './Menu';
import { Link, useLocation } from 'react-router-dom';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import useHeaderStyles from './useHeaderStyles';
import PatchNoteModal from 'components/components/PatchNoteAdminPage/components/PatchNoteModal';

const Header = ({
  className,
  defaultBackground,
  defaultIsAbsolute,
  defaultIsWhite,
  defaultIsVisible,
  defaultHaveLinks,
}) => {
  const { background, isAbsolute, isWhite, isVisible, haveLinks } =
    useHeaderStyles({
      defaultBackground,
      defaultIsAbsolute,
      defaultIsWhite,
      defaultIsVisible,
      defaultHaveLinks,
    });
  const [showSidebar, toggleSidebar] = useToggle(false);

  const authFlowCtrl = useAuthFlowManager();
  const { getAuthModal } = authFlowCtrl;
  const { pathname } = useLocation();

  return (
    <>
      {isVisible && (
        <>
          <Wrapper
            background={background}
            isAbsolute={isAbsolute}
            white={isWhite}
            className={className}
          >
            {pathname === '/' && <PatchNoteModal />}
            <Container background={background}>
              <IconButton as={Link} to="/">
                <Logo white={isWhite} />
              </IconButton>

              {/* Mobile */}

              <IconButton
                className="md-hidden text-inherit"
                onClick={toggleSidebar}
              >
                <HamburgerIcon />
              </IconButton>

              {/* Desktop */}
              <Menu className="hidden md-flex" haveLinks={haveLinks} />
            </Container>
          </Wrapper>
          <Sidebar visible={showSidebar} toggleVisible={toggleSidebar} />
        </>
      )}
      {getAuthModal()}
    </>
  );
};

export default Header;

const Wrapper = styled.header`
  width: 100%;
  z-index: 1000;
  background: ${({ background }) => background};
  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'relative')};
  color: ${({ white }) =>
    white ? 'var(--color-white)' : 'var(--color-indigo-500)'};
  top: 0;
  transition: all 300ms ease-in-out;
  pointer-events: none;

  @media (max-width: 769px) {
    overflow: hidden;
    max-width: 100vw;
  }
`;

const Container = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  height: 100px;

  @media (max-width: 769px) {
    width: 100vw;
    overflow: hidden;
    height: 59.5px;
    background: ${({ background }) => background};
  }
`;
