import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'ไม่มีครีเอเตอร์ที่จะแสดง',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(สั่งซื้อ)#1#2(บริการทางการตลาด)#2
  #3(ครีเอเตอร์)#3#4(ทั่วโลก)#4
  #5(แค่คลิกเดียว)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'ลองค้นหา "การตลาดแฟชั่น"',
  [InfluKeys.search.option2]: 'ลองค้นหา "รีวิววิดีโอเกม"',
  [InfluKeys.search.option3]: 'ลองค้นหา "รีวิวสินค้า"',
  [InfluKeys.search.option4]: 'ลองค้นหา "รีวิวอาหาร"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'เข้าร่วมเป็นครีเอเตอร์',

  // Mobile
  [InfluKeys.M.title]:
    'สั่งซื้อบริการทางการตลาดสำหรับครีเอเตอร์ระดับโลกได้ในคลิกเดียว',
  [InfluKeys.M.search]: 'ค้นหาครีเอเตอร์',

  // Card
  [InfluKeys.Card.my_card]: 'บัตรของฉัน',
  [InfluKeys.Card.view_package]: 'ดูแพ็กเกจ',
  [InfluKeys.Card.creator_profile]: 'โปรไฟล์ครีเอเตอร์',
  [InfluKeys.Card.view_packages]: 'ดูแพ็กเกจ',
  [InfluKeys.Card.view_analytics]: 'มุมมองการวิเคราะห์',
  [InfluKeys.Card.send_a_message]: 'ส่งข้อความ',
  [InfluKeys.Card.view_profile]: 'ดูโปรไฟล์',
};
