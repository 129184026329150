import useScript from 'hooks/useScript';
import { useState } from 'react';
import { forwardRef } from 'react';
import { generateAuthProtection } from '..';
import { useEffect } from 'react';
import AxiosClient from '../AxiosClient';
import { useCallback } from 'react';
import { useImperativeHandle } from 'react';
import Modals from './Modals';
import { countrySubdomains } from 'constants/languages';

const URL = 'https://accounts.google.com/gsi/client';
const client_id = process.env.REACT_APP_GOOGLE_API_CLIENT_KEY;

//error codes

const ERROR_CODES = {
  416: 'enabled by other creator',
  417: 'subscriber count is too low',
  418: 'scope is not valid',
  419: 'CSRF validation failed',
  421: 'google API error',
  429: 'too many requests',
  430: 'already a creator',
  431: "links doesn't belong to the user",

  500: 'internal server error',
};
let myScope = null;
const YoutubeVerifier = forwardRef(
  (
    {
      reqType = 'permanent',
      onVerify,
      onError,
      setShowPopupDisclaimer,
      errorModalPrimaryButtonClick,
    },
    ref
  ) => {
    const GApiReadyStatus = useScript(URL, true, true, true);
    const [gApi, setGApi] = useState(null);
    let subDomain = window.location.hostname.split('.')[0];
    // check if the subdomain is a key in the countrySubdomains object
    if (!countrySubdomains.hasOwnProperty(subDomain)) {
      subDomain = null;
    }
    const [error, setError] = useState({
      code: null,
      message: null,
    });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      if (GApiReadyStatus === 'ready' && window.google && !gApi) {
        setGApi(window.google);
      }
    }, [GApiReadyStatus, gApi]);

    const handleCallback = useCallback(
      (TokenResponse) => {
        setLoading(true);
        if (
          !gApi.accounts.oauth2.hasGrantedAnyScope(
            TokenResponse,
            myScope[0],
            ...myScope.slice(1)
          )
        ) {
          setError({
            code: 418,
            message: ERROR_CODES[418],
          });
          onError(418);
          return;
        }

        if (TokenResponse.error) {
          setError({
            code: TokenResponse.error,
            message: TokenResponse.error_description,
          });
          onError(TokenResponse.error);
          return;
        }

        AxiosClient.post('/addLink', {
          auth_code: TokenResponse.code,
          platform: 'youtube',
          reqType,
          state: TokenResponse.state,
          subDomain,
        })
          .then((res) => {
            onVerify(res.data);
            setError({
              code: null,
              message: null,
            });
          })
          .catch((err) => {
            setError({
              code: err.response.status,
              message: ERROR_CODES[err.response.status],
            });
            onError(err.response.status);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      [gApi, reqType, onVerify, subDomain, onError]
    );

    const handleVerification = useCallback(async () => {
      if (gApi) {
        const { state, scope } = await generateAuthProtection('youtube');
        myScope = scope;
        const client = gApi.accounts.oauth2.initCodeClient({
          client_id,
          ux_mode: 'popup',
          scope: scope.join(' '),
          state: state,
          select_account: true,
          callback: handleCallback,
        });
        client.requestCode();
        if (client.h === null) {
          setShowPopupDisclaimer(true);
        }
      }
    }, [gApi, handleCallback, setShowPopupDisclaimer]);

    useImperativeHandle(ref, () => ({
      verify: handleVerification,
      loading,
    }));

    return (
      <Modals
        error={error}
        onClose={() => {
          setError({
            code: null,
            message: null,
          });
          onError({ openReconnectionModal: true });
        }}
        onPrimaryButtonClick={errorModalPrimaryButtonClick}
      />
    );
  }
);

export default YoutubeVerifier;
