import Router from 'routes';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import MissionCompleteBanner from 'components/Basics/Banners/MissionCompleteBanner';
import { OfficialLaunchModalProvider } from 'contexts/OfficialLaunchModal';
import { ToastProvider } from 'components/Basics/Toast';
import { AuthFlowManagerProvider } from 'contexts/AuthFlowManagerContext';
import { SocketProvider } from 'contexts/SocketContext';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { CurrentUserProvider } from './contexts/CurrentUserContext';
import device from 'styles/device';
import { WebViewIssueBannerProvider } from 'contexts/WebViewIssueBannerContext';
import { AutoTranslationProvider } from 'contexts/AutoTranslationContext';
import { NotificationSoundProvider } from 'contexts/NotificationSoundContext';
import ErrorBoundary from 'components/components/Utilities/ErrorBoundary';

const queryClient = new QueryClient();

const App = () => {
  const { isMobile } = useResponsiveScreen();

  return (
    <HelmetProvider>
      <SocketProvider>
        <AutoTranslationProvider>
          <NotificationSoundProvider>
            <CurrentUserProvider>
              <QueryClientProvider client={queryClient}>
                <ToastProvider showProgress={isMobile}>
                  <OfficialLaunchModalProvider>
                    <WebViewIssueBannerProvider>
                      <AuthFlowManagerProvider>
                        <ErrorBoundary>
                          <Container>
                            <Router />
                          </Container>
                        </ErrorBoundary>
                        <MissionCompleteBanner />
                      </AuthFlowManagerProvider>
                    </WebViewIssueBannerProvider>
                  </OfficialLaunchModalProvider>
                </ToastProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </CurrentUserProvider>
          </NotificationSoundProvider>
        </AutoTranslationProvider>
      </SocketProvider>
    </HelmetProvider>
  );
};
export default App;

const Container = styled.div.attrs({
  className: 'main-container',
})`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: min-content;
  position: relative;
  background-color: var(--color-pink-50);
  background-attachment: fixed;

  @media ${device.md} {
    width: max(100%, 1280px);
  }
`;
