import IndigoLongButton from 'components/Basics/Buttons/IndigoLongButton';
import Image from 'components/Basics/Image';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useMemo } from 'react';
import { createContext, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import InvitationKeys from 'translations/translationKeys/InvitationPageKeys';
import { useLocation } from 'react-router-dom';

const removeModalFrom = ['brand-invitation'];

const WebViewIssueBannerContext = createContext({
  openWebViewIssueBanner: () => {},
  isNative: false,
});

const WebViewIssueBannerProvider = ({ children }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { pathname } = useLocation();

  const isNative = useMemo(() => {
    const nua = navigator.userAgent.toLowerCase();

    return (
      nua.indexOf('mozilla/5.0') > -1 &&
      nua.indexOf('android ') > -1 &&
      nua.indexOf('applewebkit') > -1 &&
      nua.indexOf('version') > -1
    ); //native browser?
  }, []);

  const data = useMemo(() => {
    return {
      openWebViewIssueBanner: () => {
        setOpen(true);
        sessionStorage.setItem('webViewBannerShown', false);
      },
      isNative,
    };
  }, [isNative]);

  if (removeModalFrom.some((path) => pathname.includes(path))) {
    return <>{children}</>;
  }

  return (
    <WebViewIssueBannerContext.Provider value={data}>
      {children}
      <AnimatePresence>
        {open &&
          isNative &&
          !JSON.parse(sessionStorage.getItem('webViewBannerShown')) && (
            <>
              <Backdrop onClick={(e) => e.stopPropagation()} />
              <Wrapper>
                <Title>{t(InvitationKeys.Browser.title)}</Title>

                <Icons>
                  <Image
                    src="img\browsers\Google Chrome.png"
                    width="42px"
                    height="42px"
                    contain
                  />
                  <Image
                    src="img\browsers\Safari.png"
                    width="42px"
                    height="42px"
                    contain
                  />
                  <Image
                    src="img\browsers\Firefox.png"
                    width="42px"
                    height="42px"
                    contain
                  />
                  <Image
                    src="img\browsers\Explorer.png"
                    width="42px"
                    height="42px"
                    contain
                  />

                  <Image
                    src="img\browsers\Opera.png"
                    width="42px"
                    height="42px"
                    contain
                  />
                </Icons>

                <Description>
                  {t(InvitationKeys.Browser.description)}
                </Description>

                <IndigoLongButton
                  onClick={() => {
                    setOpen(false);
                    sessionStorage.setItem('webViewBannerShown', true);
                  }}
                >
                  {t(InvitationKeys.Browser.button)}
                </IndigoLongButton>
              </Wrapper>
            </>
          )}
      </AnimatePresence>
    </WebViewIssueBannerContext.Provider>
  );
};

const Backdrop = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { delay: 0.3 },
})`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const Wrapper = styled(motion.div).attrs({
  initial: { y: '100%' },
  animate: { y: 0 },
  exit: { y: '100%' },
  transition: { delay: 0.3 },
})`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 1.5rem 1rem 1.75rem 1rem;
  border-radius: 0.625rem 0.625rem 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  background: white;
  z-index: 99999;
`;

const Title = styled.h3`
  color: var(--color-indigo-500);
  text-align: center;
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 1.3rem */
  letter-spacing: 0.00938rem;
`;

const Icons = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Description = styled.p`
  color: var(--color-indigo-500);
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
  letter-spacing: 0.15px;
`;

const useWebViewIssueBanner = () => useContext(WebViewIssueBannerContext);

export { WebViewIssueBannerProvider, useWebViewIssueBanner };
