import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'Không hiển thị người sáng tạo',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(Dịch vụ)#1 #2(Marketing cho)#2 
  #3(nhà sáng tạo)#3 #4(toàn cầu)#4 
  #5(nhấp vào đây để đặt hàng)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'Try "Marketing thời trang"',
  [InfluKeys.search.option2]: 'Try "Review trò chơi điện tử"',
  [InfluKeys.search.option3]: 'Try "Review Sản phẩm"',
  [InfluKeys.search.option4]: 'Try "Review thức ăn"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'Đăng ký làm người sáng tạo',

  // Mobile
  [InfluKeys.M.title]:
    'Dịch vụ Marketing cho nhà sáng tạo toàn cầu nhấp vào đây để đặt hàng',
  [InfluKeys.M.search]: 'Tìm kiếm nhà sáng tạo nội dung',

  // Card
  [InfluKeys.Card.my_card]: 'Thẻ của tôi',
  [InfluKeys.Card.view_package]: 'Xem gói dịch vụ',
  [InfluKeys.Card.creator_profile]: 'Hồ sơ của nhà sáng tạo nội dung',
  [InfluKeys.Card.view_packages]: 'Xem gói dịch vụ',
  [InfluKeys.Card.view_analytics]: 'Xem thống kê',
  [InfluKeys.Card.send_a_message]: 'Gửi Tin nhắn',
  [InfluKeys.Card.view_profile]: 'Xem Hồ sơ',
};
